.footer{
    color: #ffffff;
.footerbox1{
    padding: 50px 0px;
    background-color: #FFA20D;

   .contactnum{
        font-style: normal;
        font-weight: 600;
        font-size: 27px;
        line-height: 35px;
        color: #033B64;
   }

   .callus{
        font-weight: 300;
        font-size: 14px;
        line-height: 15px;
   }
   li{
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #033B64;
    margin: 10px 0px;
}
p{
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #033B64;
}
h4{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #033B64;
}
}

.footerImg{
    padding: 5px;
    border-radius: 50%;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    // cursor: pointer;
}

.footerbox2{
    //padding: 50px 50px;
    background-color: #033B64;
        a{
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #FFFFFF;
        }
    li{
        margin: 10px 0px;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #FFFFFF;
    }
}
}
.footerbox2-container{
    padding: 50px 50px;
}
// For Mobile devices
@media screen and (max-width: 768px) { 
.footer{
    .footerbox1{
        .justify-content-center{
            justify-content: flex-start !important;
            
        }
    }
    .footerbox2, .footerbox1{
        padding-left: 39px;
    }
}
}
.px-0{
h5{
    font-weight: 400;
}
}

.downloadButton {
    gap: 10px;
    background: #FFA20D;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #033B64;
    float: right
}