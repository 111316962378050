.subscription_background {
    background-image: url('../../../assets/images/subscriptionbbackground.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 1920px;
    // height: 1024px

    // padding: 23% 0px;
    //  position: relative;
    // text-align: center;

}

.subscription_box_details_center {
    // width: 426px;
    // height: 459px;
    // border-radius: 20px;
    // position: relative;
    // background-color: #033B64;
    // // width: 371px;
    // // height: 564px;
    // text-align: left;
    // // margin-right: 2%;
    // // margin-left: 2%;
    // box-shadow: 0px 10px 10px 0px #033B6440;

    width: 370px;
    height: 445px;
    border-radius: 20px;
    position: relative;
    background-color: #033B64;
    text-align: left;
    box-shadow: 0px 10px 10px 0px #033B6440;

    h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        color: #FFFFFF;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        // margin-bottom: 0px;
        color: #FFFFFF;
    }

    button {
        position: absolute;
        background-color: #fff;
        bottom: 5%;
        text-align: center;
        padding: 10px 10px;
        color: #033B64;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0px;
        border-radius: 4px;
    }

    img {
        margin-right: 15px;
    }
}

.subscription_details_box {
    // position: relative;
    // background-color: #fff;
    // width: 371px;
    // height: 553px;
    // text-align: left;

    position: relative;
    background-color: #fff;
    width: 370px;
    height: 553px;
    text-align: left;
    border: 1px solid #033B64;
    border-radius: 20px;

    h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        margin-bottom: 0px;
    }

    button {
        position: absolute;
        background-color: #033B64;
        bottom: 5%;
        text-align: center;
        padding: 10px 10px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0px;
        border-radius: 4px;
        left: 12%;
    }

    img {
        margin-right: 15px;
    }

    span {
        font-size: 10px;
        background-color: #033B64;
        border-radius: 6px;
        padding: 2px 10px 2px 10px;
        color: #fff;
    }
}

.subplan_container{
    flex-direction: row;
    align-items: center;
    border: 1px solid #0000001A;
    // border: 1px solid #033B64;
    border-radius: 20px;
    justify-content: space-around;
}