.quote-container {
    padding: 39px !important;
   
}

.quote-confirmation-content {
    margin-top: 20px;
    input{
        margin-right: 10px;
        margin-top: 5px;

    }
}

.quote-confirmation-button{
    justify-content: space-between;
    button{
        background: #033B64;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        width: 45%;
        height: 10vh;
        border: none;
        text-align: center !important;
    }

}
