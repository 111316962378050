.button5{
    background-color:#FFA20D;
    position: absolute;
    right: 333px;
    height: 60px;
    bottom: 128px;
    font-size: 18px;
}
.save2 {
    @media screen and (max-width: 319px) {
        position: absolute ;
        right: auto ;
        font-size: 12px;
        width:55px;
        padding: 7px ;
        bottom: 40px ;
    }
    @media screen and (min-width:320px) and (max-width:768px) {
        bottom: 28px ;
        right:auto;
    }
    @media screen and (min-width:769px) {
        bottom: 100px ;
        right:245px;
        position:absolute;
    }
}
.cancel2 {
    @media screen and (max-width: 319px) {
        width: 63px ;
        left: 85px;
        font-size: 12px;
        bottom:40px;
        padding: 7px;
    }
    @media screen and (min-width:320px) and (max-width:768px) {
        bottom: 28px ;
        position:absolute;
        left:115px ;
    }
    @media screen and (min-width:769px) {
        bottom: 100px;
        right:125px;
        position:absolute;
    }
}
.newBut {
    @media screen and (min-width: 769px) {
        position: absolute !important;
        right: 150px !important;
        height: 60px !important;
        bottom: 128px !important;
        font-size: 18px !important;
    }

    @media screen and (max-width: 319px) {
        right: auto !important;
        position: absolute;
        height: 60px !important;
        bottom: 40px !important;
        padding: 5px !important;
    }
}



@media screen and (max-width: 600px) {
    .projectname{ 
        font-size: 1.4rem;


}
.projectdetail{
    font-size: 1.4rem;
}
.about{
    font-size: 1.4rem;
    color:#16213E;
}
   
    .adLine1{
        width: 85%;
    }
    .adLine2{
        width: 85%;
    }
    .city{
        width: 85%;
    }
}
@media screen and (max-width:767px){
    .aboutProject .projectInfo {
        margin-top: 0;
    }
}

    @media screen and (max-width:400px) {
        .projectname{
        font-size: 1.2rem;

    }
    .about{
        font-size: 1.2rem;
    }
    .projectdetail{
        font-size: 1.2rem;
    }
  
}
.toggletext{
    position:absolute;
    margin-top: 12px;

}
.px-5 p {
margin-top: 7px;
}
.landsizecontainer{
    @media screen and (min-width:768px){
        margin-left:3rem !important;
    }
    }


    .quote-page-content{
        p{
            margin-top: 30px;
            margin-bottom: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        h6{
            font-size: 14px;
            line-height: 15px;
            color: #00215B;
        }
        span{
            font-size: 20px;
            line-height: 26px;
            color: #16213E;
            font-weight: bold;
        }
        img{
            width: 4% !important;
            margin-right: 10px;
            border-left: none !important;
            height: unset !important;
        }
    }
    .quote-page-buttons{
        display: flex; 
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    p{
         margin-top: 33px;
        //  margin-left: 50px;
      }
    span:nth-child(1){
        margin-right: 18px;
    }
    span:nth-child(2) {
        margin-right: 10px;
        }
    img{
        width: 10% !important;
        border-left: none !important;
    }
    }

    .quotes-heading{
        h6{
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            color: #16213E;
            margin-left: 40px;
            cursor: pointer;
            // margin-bottom: -2%;
           
        }
        
    }
    .active-quote{
            background: rgba(255, 162, 13, 0.1);
            border: 1px solid #FFA20D;
            box-sizing: border-box;
            border-radius: 22px;
            padding: 5px;
            cursor: pointer;
    }
    .quote-top-heading{
        margin-top: 50px;
    }

    @media screen and (min-width:457px) and (max-width:768px) {
        .quotes-heading {
            h6{
                font-size: 16px;
                margin-left: 35px;
            }
            
        }
        .quote-page-content{
            width: 50% !important;
        }
        .quote-top-heading{
            margin-top: 25px;
        }
        .quote-page-buttons {
            img {
                width: 31% !important;
            }
            p{
                margin-left: -5px;
            }
            span:nth-child(1) {
                margin-right: 10px;
                }
        }
    }
    @media screen and (min-width:320px) and (max-width:456px) {
        .quotes-heading {
            h6{
                font-size: 12px;
                margin-left: 27px;
            }
            
        }
        .quote-page-buttons {
            flex-direction: row !important;
            justify-content: unset;
            p{
                margin-left: 25px;
                margin-right: 100px;
                margin-top: unset;
            }
            img {
                width: 30% !important;
            }
            h6{
                font-size: 1rem;
            }
        }

        .quote-page-buttons {
            span:nth-child(1) {
            margin-right: 10px;
            }
        }

        .quote-top-heading{
            margin-top: 25px;
        }

        .Projects{
            li{
                display: unset !important;
            }
        }
    }
