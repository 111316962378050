// Primary Color Palette
$white: #FFFFFF;
$cool-grey1: #FBFCFC;
$cool-grey2: #F2F4F5;
$cool-grey3: #EEF1F3;
$cool-grey4: #EBEEF0;
$cool-grey5: #E6EAED;
$cool-grey6: #DDE2E6;
$cool-grey7: #C7CDD2;
$cool-grey8: #B1B9BF;
$cool-grey9: #9BA4AA;
$cool-grey10: #868F96;
$cool-grey11: #646A70;
$cool-grey12: #44484C;
$cool-grey13: #2A2C2D;
$cool-grey14: #191919;
$cool-grey15: #CEF0FF;
$cool-grey17: #c7c7c7;
$cool-grey18: #E3E0E0;
$cool-grey19: #f5f5f5;
$white-grey-1: #E7E7E7;
$white-grey-2: #C4C4C4;
$dark-gray:#262626;
$white-grey: #f8f7f7;
$light-gray: #767676;
$black: #000000;
$primary-color: #033B64;
$secondary-color:#FFA20D;
$btn-blue:#2F80ED;
$btn-orange:#FF4949;
$btn-green:#00A824;
//add new colors
$pretty-shady: rgba(0, 0, 0, 0.5);
$cyan-blue: #00629B;
$dark-cyan-blue: #00274A;
$vivid-blue-light:#008BD9;
$dark-blue: #015383;
$dark-blue1: #0062B2;
$hover-dark-blue: #102E4A;
$deep-blue: #0B7DBB;
$hover-deep-blue: #003059;
$sharp-blue: #256CEE;
$sky-blue: #0272B6;
$ocean-blue: #00619A;
$vivid-blue: #2B9CD8;
$vivid-blue-hover: #3FB6F5;
$navy-blue: #1150C4; // ?
$light-orange: #d64500;
$light-deep-orange: #aa5000;
$cherry-red: #D92324;
$cherry-red-background: #FCF2F2;
$forest-green: #0E8751;
$cyan-blue-dark: #003057;
$banner-sub: #333333;
$pattens-blue: #daecf8;
$light-blue: #D8DDE6;
$medium-dark: #001019;
$Matisse: #436179;
$Black-Pearl: #011e36;
$plan-header:#286196;
$White-smoke: #f7f7f7;
$cerulean: #0077BC;
$prussian-blue: #002f58;
$light-white-smoke: #f8f8f8;
$whisper-white: #ebebeb;
$transparent-white-10: rgba(255, 255, 255, .1);
$ebony-black: #2b2c2e;
$blue-gradient-base: #0065e0;
$grey-90: #171717;
$grey-72: #464646;
$cool-grey16: #f2f2f2;
$login-blue: #0073D1;
$lightslategrey: #768798;
$cool-grey-98: #fafafa;
$curious-blue:#337ab7;
$header-blue: #271F2E;
// Secondary Color Palette (Who knew we had this ¯\_(ツ)_/¯)
$smolder: #1F232E;
$urban: #051219;
$reign: #7155D3;
$bramble: #BC43D3;
$berry: #FF638D;
$watermelon-red: #FB543F;
$haute: #FF5A5A;
$tangerine: #FF9271;
$yellow-pumpkin: #FFA700;
$yellow-squash: #FBBE4B;
$lemon: #FFD871;
$turq: #50C3D5;
$lagoon: #1BCFE1;
$green-tea: #1EE2C9;
$minty: #65D0A7;
$seafoam: #47C684;
$box-shadow: rgba(42, 44, 44, 0.2);
$box-shadow1:rgba(0, 0, 0, 0.33);
$black-shadow: rgba(0, 0, 0, 0.1);
$info-box-background: rgba($color: $vivid-blue-hover, $alpha: 0.1);
$transparent: rgba(255, 255, 255, 0);
$split-gradient1: rgba(254, 254, 254, 1);
$split-gradient2: rgba(251, 251, 251, 1);
// colors starting with $grey is only for coverage map. Nobody should use them.
$coverage-bg1: rgba(242, 242, 242, 0.66);
$coverage-bg2: rgba(216, 216, 216, 0);
$grey-1: #F9F9F9;
$grey-81: #2E2E2E;
$grey-9: #E8E8E8;
$grey-4: #747474;
// used in network page hero module.
$skynet-blue: #D6EDF8;
$background-blue: #EDF3FD;
// Used for promo badge in activation and customizer for number selection
// The actual color hides the border, so changed the color and added opacity
// for the workaround
$promo-blue: rgba(70, 138, 255, 0.1);
$tooltip-border1 : rgba(136, 183, 213, 0);
$tooltip-border2: rgba(194, 225, 245, 0);
$search-overlay: rgba(0, 0, 0, 0.5);

 