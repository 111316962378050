.findProjets {
    width: 100%;
    // background-image: url("../../../assets/images/Bgimage.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
        // background-color: #033b64;
        opacity: 0.7;
        padding: 150px 0px;
        color: #ffffff;
        p {
            text-align: justify;
        }
       
    }
    h1 {
        // font-size: 100px;
        font-size: 55px;
        // font-weight: 600 !important;
    }
    .knowmore {
        padding: 15px 25px;
        background-color: #fab005;
        border: 0px;
        color: #ffffff;
        font-size: 13px;
    }
    p {
        font-size: 15px;
    }
}

.projectsList {
    span {
        color: #033b64;
        font-weight: 700;
        font-size: 18px;
    }

    ul {
        margin: 0px;
        padding: 0px;
        li {
            // border-left: 5px solid #fab005;
            margin-top: 40px;
            img {
                width: 100%;
                height: 100%;
            }

            h4 {
                font-weight: 700;
               
            }

            a {
                color: #033b64;
                text-decoration: none;
            }
        }
    }
}
.new{
  
    // @media screen and(min-width:339px) and (max-width:577px) {
    //     margin-right: auto !important;
    // }
    @media screen and (min-width:768px) {
        margin-right: 3%;
    }
}
.row1{
    @media screen and (min-width:307px) and (max-width:767px) {
        margin-right: 0% ;
    }
}
.col-md-7{
    .projectname{
       
        @media screen and (min-width:250px) and (max-width:400px) {
            font-size: 1.1rem;
            
        }
        @media screen and (max-width:249px) {
            font-size: 0.9rem;
            
        }
    }
}
.projectname{
    color:#033b64;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0.5px 4px !important;
}
