.heading {
    margin-top: 25px;
    color: #033b64;
    font-size: 20px !important;
    display: flex;
}
.title {
    color: #033b64;
    font-size: 22px;
    font-weight: bold;
}
.buildercards img {
    width:350px;
    box-shadow: 0px 0px 5px 3px lightgrey;
    margin-top: 10px;
}
// .password {
//     color: #033b64;
//     font-weight: bold;
//     margin-top:20px;    
// }

// .button2 {
//     background-color: #033b64;
//     color: white;
//     font-size: 20px;
//     border: none;
//     padding: 10px;
// }
.button3{
    background-color: orange;
}
.change { 
    color: #033b64;
    font-size: 20px;
    font-weight: bold;
}


.TabProfileFalse {

    font-size: 22px;
    font-weight: bold;
    box-sizing: none;
    text-decoration: underline;
    text-decoration-color: blue !important;
  
}

.TabProfileTrue {
    text-decoration-color: orange !important;
    text-decoration: underline;
//     margin-left:-20px;
//     font-size: 22px;
//     font-weight: bold;
//    box-sizing: none;
}

.TabSettingFalse {
  margin-left: 60px !important;
  font-weight: bold !important;
  font-size: 22px !important;
  box-sizing: none;
  
  
}
.TabSettingTrue {
    text-decoration-color: orange !important;
    text-decoration: underline;
    margin-left:60px;
    font-size: 22px;
    font-weight: bold;
   box-sizing: none;
}
.twitter {
    border: 1px solid orange;
    width: 50px;
    height: 45px;
    padding: 10px;
    border-radius: 50%;
    margin-left: 10px;
}
.offset {
    display: inline;
}
.linkedin {
    border: 1px solid orange;
    width: 50px;
    height: 45px;
    padding: 10px;
    border-radius: 50%;
    margin-left: 10px;
}
.facebook {
    border: 1px solid orange;
    width: 50px;
    height: 45px;
    padding: 10px;
    border-radius: 50%;
    margin-left: 10px;
}



.toggle-switch {
 
  margin-top:15px;
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #fff;
        border-radius: 20px;
        margin: 0;
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 34px;
            padding: 0;
            line-height: 34px;
            font-size: 14px;
            color: white;
            font-weight: bold;
            box-sizing: border-box;
        }
        &:before {
            content: 'Yes';
            text-transform: uppercase;
            padding-left: 10px;
            background-color:#033b64;
            color: #fff;
        }
    }
    &-disabled {
        background-color: #fff;
        cursor: not-allowed;
        &:before {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: 'No';
        text-transform: uppercase;
        padding-right: 10px;
        background-color: #bbb;
        color: #fff;
        text-align: right;
    }
    &-switch {
        display: block;
        width: 24px;
        margin: 5px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
        }
    }
}
.logoutbtn{
    position: absolute;
    right: 385px;
    height: 60px;
    bottom: 135px;
    font-size: 18px;
}
.editbtn{
    position: absolute;
    right: 230px;
    height: 60px;
    bottom: 135px;
    font-size: 18px;
}
.fa{
    color:#033b64;
}
.fa-usd{
    color:#033b64;
    font-size:45px;
    font-style: normal;
    font-weight: bold;
    }
    .month-text{
        color:#033b64;
        font-size:20px;
        color:rgba(3,59,100,0.7)''
    }
    .plan-button{
        font-size: 15px;
color: #033b64;
font-weight: bold;
padding: 10px 25px 10px 25px;
border-radius: 0px;
margin: 10px;
border: none;
    }
    .plan-button-two{
        font-size: 15px;
        color:white;
        font-weight: bold;
        padding: 15px 60px 15px 60px;
        border-radius: 50px;
        margin: 10px;
        border-color: white;
        background-color: #033b64;
    }
    .button-one{
        text-align: center;
        width:215px;
        height:300px;
    }
    .para-text{
        color:#033b64;
        font-size:25px;
    }
    .second-section{
        background-color: #033b64;
        color:white !important;
    }
    .font{
        color:white;
    }