#privacypolicy {
    // width: 96%;
    // margin-left: 2%;
    text-align: justify;
    // margin-top: 10%;
    // margin-bottom: 5%;
    
    .PP {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 50px;
        line-height: 30px;
        color: #16213E;
        margin-top: 2%;
        margin-bottom: 2%;
    }

    .uptd {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 15px;
        color: #00215B;
        margin-bottom: 3%;
    }

    .ah {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #41444B;
        margin-bottom: 1%;
    }

    .ahheadings {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #16213E;
        margin-top: 2%;
    }

    .li {
        margin-left: 2%;
    }

    .ul {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        margin-top: -1.5%;
        margin-left: -2%;
    }
}
