.purchaseOrderPage_background {
    width: 895px;

}

.purchaseOrder_header_container {
    background-color: #033B64;
    color: #ffff;
}

.purchaseOrder_header {
    justify-content: space-between;
    align-items: center;

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 0px;
    }

    img {
        margin-right: 10px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;

    }
}

.purchaseOrder_banner {
    background-color: #033B641A;
    justify-content: space-between;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        margin-bottom: 0;
        color: #033B64;
    }
}

.purchaseOrder_container {
    background-color: #ffff;

    h3 {
        color: #033B64;
        font-size: 20px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0px;

    }

    p {
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;


    }
}

.purchaseOrder_container_box {
    align-items: center;
    background-color: #033B641A;
    padding: 14px 63px 14px 25px;

    p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #000000;
        margin-left: 15px;
    }
}