.bank-managed{
    margin-left: 3%;
    margin-top: 6%;
    width: 85%;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    h6{
        height: 26px;
        left: 31.61%;
        right: 53.91%;
        top: calc(50% - 26px/2 + 281.5px);
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: #16213E;
    }
}


.grid{
    max-width: 50% !important;
    flex-basis: 50% !important;
}

.dropdown-heading{
    margin-top: 10%;
    text-align: center;
}

.multiselect{
    margin-top: 10%;
    margin-left: 10%;
}

.optionContainer{
    height: 250px;
}

.cross-icon{
    margin-right: 1rem;
    cursor: pointer;
    
}

.city-name-1{
    line-height: 30px;
    font-size: 24px;
    color: #00215B;
}


