.bg-img{
   
.button2{
    @media screen and (max-width:319px){
        padding:7px;
    margin-right: -2%;

    bottom: 47px;
    right: unset;
    }
}
}

.Projects .row.align-items-center {
        .px-3 {
            @media screen and (max-width:319px) {
       margin-bottom: 10px;
        }
    }
    
}
.new{
    @media screen and (min-width:768px) {
        margin-right: 3%;
    }
}
.content-left{
    margin-left: 0rem;
}
.view-pro1-arrow{
    height: 10px;
    width: 10px;
}