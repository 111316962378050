.textarea{
    @media screen and (max-width:400px) {
        width:94%
    }
    @media screen and (min-width:401px) and (max-width:600px) {
        width:95%
    }
}
.details,.newProject{
    color:#00215B;
    font-weight: 600;
}
.address{
    @media screen and (max-width:400px) {
        .adLine1{
            width: 80%;
        }
        .adLine2{
            width: 80%;
        }
    }
  
}
.phone{
    @media screen and (max-width:400px) {
        width: 80%;
    }
}
.builderType{
    @media screen and (max-width:400px) {
        width: 80%;
    }
}
.landsize{
    @media screen and (max-width:400px) {
        width: 80%;
    }
}
.toggletext{
        position:absolute;
        margin-top: 12px;
    
}
.Projects p {
    margin-top: 7px;
}
.hire{
    background-color:#033B64 !important;
}

.step-heading{
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 38px;
        color: #9F9F9F;
    }
    .active-step {
            color: #00215B;
    }
}
.contailner-heading{
    p{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 38px;
        color: #00215B;
    }
    span{
        font-weight: 400;
        font-size: 14px;
        line-height: 38px;
        text-decoration-line: underline;
        color: #2F80ED;
        cursor: pointer;
    }
}
 button{
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        text-align: right;
        color: #FFFFFF;
        background: #033B64;
        border: none;
    }
.Previous{
        background: #FFFFFF;
        border: 1px solid #033B64;
        font-size: 20px;
        line-height: 24px;
        color: #033B64;
    }

    .land-size{
        select{
            border: none;
            position: relative;
            right: 9%;
            font-style: italic;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            color: #41444B;
        }
        span{
            position: relative;
            right: 9%;
            font-style: italic;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            color: #41444B;
        }
    }