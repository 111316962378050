@import '../../../styles/index.scss';
.main-text{
  display: flex;
  justify-content: center;
  padding: 0 20px;
  .button-sctn{
    text-align: center;
  }
  .box-section{
    max-width: 750px;
  }
.not-found-header {
    background: $primary-color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    font-family: AH Bold;
font-style: normal;
font-weight: bold;
font-size: 200px;
line-height: 260px;
display: flex;
justify-content: center;
}
.not-found-content {
  font-family: AH Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: $light-gray;
  margin-top: -40px;
  text-align: center;
  .oops {
    display: flex;
    justify-content: center;
  }
  .button {
    display: flex;
    justify-content: center;
  }
 
}

.nav-home-btn {
@extend .blue-btn;
 padding:1em 2em;
}
.nav-home-btn:hover {
  color: $white;
  text-decoration: none;
}
}

@media screen and (max-width:789px) {
  .not-found-header {
    font-size: 150px;
  }
}