.hire-container{
    padding: 30px;
    font-style: normal;
    button{
        img{
            height: 36%;
            margin-bottom: 2px;
            width: 9%;
        }
    }

}

.hire-header{
    display: flex;
    justify-content: space-between;
    // margin-top: 25px;
    p{
        font-weight: 600;
        font-size: 34px;
        line-height: 38px;
        color: #00215B;
        margin-bottom: 0px;  
       
    }
    img{
        margin-bottom: 12px;
        cursor: pointer;
    }
}

.hire-content{
    
        font-size: 16px;
        line-height: 28px;
        color: #41444B;
       
    

}

.hire-content-list{
    p:nth-child(1){
        font-weight: 600;
        font-size: 24px;
    }
    p{
        margin-top: 3px;
        font-size: 20px;
        line-height: 26px;
        color: #023A63;
    }
    input{
        margin-right: 20px;
    }
}
.radio-custom:before {
    content: '';
    background: #fff;
    border: 2px solid #ddd;
    display: inline-block;
    // vertical-align: middle;
    width: 22px;
    height: 22px;
    // padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.radio-custom:checked:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #FFFFFF;
    background: #023A63;
    border: 1px solid #023A63;
}

.radio-custom:before {
    border-radius: 0%;
    background: #FFFFFF;
    border: 1px solid #D4D7DD;
}
.radio-custom {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer 
}

.hr {
     margin-top: unset !important; 
}

.disabledHireButton{
    cursor: auto !important;
    opacity: 0.5;
    background: #033B64;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    width: 35%;
    height: 6vh;
    border: none;
    text-align: center;
}

.enableHireButton{
    background: #033B64;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    width: 35%;
    height: 6vh;
    border: none;
    text-align: center;
}
 