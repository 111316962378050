.aboutuspara{
    p{
        color: #41444B;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 2px;
    }
    
}
.asharyawebsite{
    color: #fab005;
}