.content-box{
    display: flex;
    justify-content: space-evenly;
    img{
        width: 100%;
    }
    h4 {
        font-size: 1.4rem;
        @media  screen and (min-width:769px) and (max-width:864px)  {
            font-size: 1.2rem;
        }
    }

    p{
        font-size: 14px;
        display: none;
        @media screen and (min-width:625px) and (max-width:767px) {
            font-size: 14px;
        }
  
    }
    .text-container{
        width: 72%;
        background-color: #033b64;
        padding: 10px;
        opacity: 0.7;
        color: #ffffff;
        float: right;
        bottom: 53px;
        margin-left: 35px;
        position: absolute;

    }
}

.evaluation{
    background-size: cover;
    background-repeat: no-repeat;

}

.mark-the-property{
    background-size: cover;
    background-repeat: no-repeat;

}

.screening-selection{
    background-size: cover;
    background-repeat: no-repeat;
 
}

.interaction-documentation{
    background-size: cover;
    background-repeat: no-repeat;

}

.maintenance-remodelling{
    background-size: cover;
     background-repeat: no-repeat;

}

.loanProcessing{
    background-size: cover;
    background-repeat: no-repeat;

}

.text-container:hover {
     height: 50vh;
    width: 70%;
    font-size: 14px;
    p{ 
        display: block;
    }
   
  }
  @media screen and (min-width:992px) and (max-width:1179px){
    .text-container:hover {
        width: 50%;
   }
   .content-box{
    .text-container{
        bottom: 32px;
        margin-left: 29px;
    }
   
   }

  }
  @media screen and (min-width:820px) and (max-width:991px){
    .content-box{
        .text-container{
            bottom: 32px;
            margin-left: 25px;
        }
    }
    .text-container:hover {
        height: 40vh;
        width: 78%;
    }

  }

  @media screen and (min-width:768px) and (max-width:819px){
    .content-box {
        .text-container{
            bottom: 23px;
            margin-left: 22px;
        }
    }
        .text-container:hover {
            height: 40vh;
            width: 78%;
        }
        
       
    
  }
  @media screen and (min-width:439px) and (max-width:468px){
    .content-box {
        .text-container{
            bottom: 29px;
            margin-left: 35px;
        }
    }
        .text-container:hover {
            height: 40vh;
            width: 78%;
        }
    
  }
  @media screen and (min-width:335px) and (max-width:439px){
    .content-box {
        .text-container{
            bottom: 24px;
            margin-left: 35px;
        }
    }
    .text-container:hover {
        height: 36vh;
        width: 70%;
        
    }

  }
  @media screen and (min-width:309px) and (max-width:334px){
    .content-box {
        p{
            font-size: 10px !important;
        }
        .text-container{
            h4{
                font-size: 0.7rem;
            }
            bottom: 19px;
            margin-left: 19px;
        }
    }
    .text-container:hover {
        height: 35vh;
        width: 74%;
        font-size: 7px;
 
    }

  }

  @media screen and (min-width:280px) and (max-width:309px){
    .content-box {
        p{
            font-size: 10px !important;
        }
        
        .text-container{
            h4{
                font-size: 0.7rem;
            }
            bottom: 18px;
            margin-left: 17px;
        }
    }
    .text-container:hover {
        height: 29vh;
        width: 74%;
        
    }

  }
@media screen and (min-width: 768px){
.ourservices-offset-md-2 {
    margin-left: 1.666667% !important;
}
 
}