.outer-container {
    //margin-top: calc( 100% - 52%);
    position: relative;
    margin: auto;
}

.homepage {
    // background-repeat: no-repeat;
    // background-position: center center;
    // background-size: cover;
    // padding: 24% 0px;
    // position: relative;
    // height: auto;
    //margin-top: 10%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 30% 0px;
    position: relative;
    height: auto;
    //padding:150px 0px;

    .overlay {
        color: #ffffff;

        p {
            text-align: initial;
            line-height: 30px;
        }

        h1 {
            font-weight: 600;
        }

    }

    // .home-knowmore {
    //     @media screen and (max-width:280px) {
    //         padding: 5px 4px !important;
    //     }
    //     @media screen and (min-width:281px) and (max-width:300px) {
    //         padding: 7px 7px !important;
    //     }
    //     @media screen and (min-width:301px) and (max-width:400px) {
    //         padding: 10px 18px !important;
    //     }
    //     @media screen and (min-width:401px) and (max-width:600px) {
    //         padding: 12px 22px !important;
    //     }
    // }
    .knowmore {
        padding: 15px 25px;
        background-color: #fab005;
        border: 0px;
        color: #ffffff;
        font-size: 13px;
        position: absolute;
        bottom: 100px;

    }


}

.toolsblock {
    box-shadow: 0 0 10px #ddd;

    .text-content {
        @media screen and (min-width:768px) and (max-width:991px) {
            margin-top: 10%;
        }
    }

    .text-content2 {
        @media screen and (min-width:992px) {
            margin-top: -1%;
        }

        @media screen and (min-width:768px) and (max-width:991px) {
            margin-top: -1%;
        }
    }
}

.box {
    width: 100%;
    height: auto;
    padding: 30px;
    font-size: 80px;
    font-weight: 500;
    color: #ffffff;

    .boxsubtitle {
        font-size: 20px;
    }
}

.box1 {
    background-color: #033b64;
}

.box2 {
    background-color: #ffa20d;
}

.box3 {
    width: 100%;
    height: auto;

    margin-top: -100px;
    font-size: 20px;
    font-weight: 500;
    background-color: #ffffff;
    border-top: 5px solid #ffa20d;

    .subBox1 {
        //height: 150px;
        display: flex;
        align-items: center;
        font-size: 23px;
        color: #033b64;
        height: auto;

        @media screen and (min-width:768px) and (max-width:1023px) {
            font-size: 21px;

        }
    }

    h5 {
        font-size: 14px;
        color: #033b64;
        margin-top: 15px;
    }

    h6 {
        font-size: 12px;
        line-height: 20px;

        @media screen and (min-width:451px) and (max-width:767px) {
            font-size: 14px;
        }

        @media screen and (min-width:992px) {
            font-size: 14px;
        }

        @media screen and (min-width:768px) and (max-width:991px) {
            font-size: 13px;
        }
    }
}

.ourmission {
    h1 {
        color: #033b64;
        font-weight: 600;
    }

    h5 {
        font-size: 18px;
        color: #033b64;
    }

    h6 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    p {
        color: #ffa20d;
    }

    .ourMissinBtn {
        padding: 15px 25px;
        background-color: #033b64;
        border: 0px;
        color: #ffffff;
        font-size: 13px;
    }
}

.ourMission {
    box-shadow: 0 0 10px #ddd;
    padding: 20px;


}

.home-ourMission {
    @media screen and (max-width:767px) {
        margin-left: 15px;
        margin-right: 15px;
    }

    @media (min-width:768px) and (max-width:991px) {
        flex: 0 0 42.333333%;
        margin-top: 3rem;
        max-height: 357px;
        max-width: 300px;

    }
}

.view {
    color: #ffffff;

    .new-box {

        @media screen and (max-width:767px) {
            width: 100% !important;
            margin-left: 0px;
        }
    }

    h2 {
        font-size: 25px;
        font-weight: 600;

        @media screen and (max-width:767px) {
            margin-left: 1rem;
        }

        @media screen and (max-width:285px) {
            font-size: 17px;
        }

        @media screen and (min-width:319px) and (max-width:767px) {
            font-size: 27px;
        }

        @media screen and (min-width:768px) and (max-width:857px) {
            font-size: 21px;
        }

        @media screen and (min-width:858px) and (max-width:1199px) {
            font-size: 23px;
        }

        @media screen and (min-width:1200px) and (max-width:1378px) {

            font-size: 27px;
        }

        @media screen and (min-width:1379px) {

            font-size: 29px;
        }
    }

    h4 {
        @media screen and (max-width:767px) {
            margin-left: 1rem;
        }

        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        @media screen and (max-width:270px) {
            margin-top: 0.5rem !important;
            margin-bottom: 0.5rem !important;
            font-size: 10px;
        }

        @media screen and (min-width:319px) and (max-width:380px) {
            font-size: 14px;
        }

        @media screen and (min-width:381px) and (max-width:413px) {
            font-size: 15px;
        }

        @media screen and (min-width:414px) and (max-width:767px) {
            font-size: 16px;
        }

        @media screen and (min-width:813px) and (max-width:870px) {
            font-size: 11px;
        }

        @media screen and (min-width:992px) and (max-width:1044px) {

            font-size: 11px;
        }

        @media screen and (min-width:1190px) and (max-width:1230px) {

            font-size: 13px;
        }

        @media screen and (min-width:1231px) and (max-width:1378px) {

            font-size: 15px;
        }

        @media screen and (min-width:1379px) {

            font-size: 18px;
        }
    }

    // for position in parent
    .viewbox1 {
        background-color: #033b64;
        height: 100%;
        position: relative;

        button {
            padding: 15px;
            background-color: #ffa20d;
            border: 0px;
            color: #ffffff;
            font-size: 13px;
        }
    }

    // for positions child
    .viewbox1,
    .viewbox2 {
        .align-self-end {
            position: absolute;
            right: 4px;
            bottom: 2px;
            width: 59px;
            height: 41px;

            @media screen and (max-width:260px) {
                width: 52px;
                height: 34px;
            }
        }
    }

    .viewbox2 {
        background-color: #ffa20d;
        height: 100%;

        button {
            padding: 15px;
            background-color: #033b64;
            border: 0px;
            color: #ffffff;
            font-size: 13px;
        }
    }
}

.ourpartners {
    text-align: center;

    h1 {
        color: #033b64;
        font-weight: 600;
    }

    h5 {
        font-size: 18px;
        color: #033b64;
        font-weight: 700;

        @media (min-width: 768px) and (max-width: 991px) {
            width: max-content;
            margin-left: 14%;
        }
    }

    h6 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        @media (min-width: 768px) and (max-width: 991px) {
            width: max-content;
            margin-left: 21%;
        }
    }

    p {
        color: #ffa20d;
        font-weight: 400;
        text-align: center;
    }

    .ourpartnersbox1 {
        box-shadow: 0 0 10px #ddd;
        padding: 10px;
        width: max-content;

        @media screen and (max-width: 767px) {
            width: 100%;
        }

        img {
            width: 150px;
            height: 150px;

            @media screen and (max-width: 200px) {
                max-width: 100px;
                height: 80px;
            }
        }
    }
}

.justify-content-around {
    h5 {
        @media screen and(min-width:1124px) {
            width: max-content;
            margin-left: 10%;
        }
    }

    h6 {
        @media screen and(min-width:1124px) and (max-width: 1199px) {
            margin-left: -7%;
        }

        @media screen and(min-width:1200px) {
            margin-left: -20%;
        }

    }
}

.client {
    box-shadow: 0 0 10px #ddd;
    padding: 10px;

    img {
        width: 100%;
    }
}

.testimonals-cards {

    width: 45%;
    backdrop-filter: blur(50px);
    border-radius: 10px;
    background: url('../../../assets/images/third-background-image.png');
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    text-align: left;

    div {
        p:nth-child(1) {
            color: #033B64;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            margin-bottom: 0px;
        }

        p {
            color: #808080;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

}

.testimonals {
    text-align: center;

    h1 {
        color: #033b64;
        font-weight: 600;
    }

    h5 {
        font-size: 18px;
        color: #033b64;
        font-weight: 700;
    }

    h6 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        @media screen and (max-width:303px) {

            font-size: 9px;
        }

        @media screen and (min-width:304px) and (max-width:319px) {
            font-size: 10px;
        }

        @media screen and (min-width:320px) and (max-width:348px) {
            font-size: 10px;
        }

        @media screen and (min-width:349px) and (max-width:376px) {
            font-size: 9px;
        }

        @media screen and (min-width:377px) and (max-width:395px) {
            font-size: 10px;
        }

        @media screen and (min-width:396px) and (max-width:415px) {
            font-size: 11px;
        }

        @media screen and (min-width:416px) and (max-width:499px) {
            font-size: 11px;
        }

        @media screen and (min-width:500px) and (max-width:767px) {
            font-size: 14px;
        }

        @media screen and (min-width:768px) and (max-width:1023px) {
            font-size: 13px;
            line-height: 18px;
        }

        @media screen and (min-width:1024px) and (max-width:1410px) {
            font-size: 14px;
        }

        @media screen and (min-width:1411px) {
            font-size: 15px;
        }

    }

    p {
        color: #ffa20d;
        font-weight: 400;
        text-align: center;
        margin-top: 12%;
    }

    button {
        padding: 15px;
        background-color: #033b64;
        border: 0px;
        color: #ffffff;
        font-size: 13px;
    }

    .our-client {
        @media screen and (max-width:263px) {
            max-height: 142px;
        }

        @media screen and (min-width:264px) and (max-width:303px) {

            max-height: 150px;
        }

        @media screen and (min-width:304px) and (max-width:319px) {

            max-height: 193px;
        }

        @media screen and (min-width:320px) and (max-width:334px) {

            max-height: 179px;
        }

        @media screen and (min-width:335px) and (max-width:389px) {

            max-height: 200px;
        }

        @media screen and (min-width:390px) and (max-width:408px) {

            max-height: 229px;
        }

        @media screen and (min-width:409px) and (max-width:416px) {
            max-height: 245px;
        }

        @media screen and (min-width:417px) and (max-width:438px) {
            max-height: 250px;
        }

        @media screen and (min-width:439px) and (max-width:494px) {
            max-height: 270px;
        }

        @media screen and (min-width:495px) and (max-width:536px) {
            max-height: 303px;
        }

        @media screen and (min-width:537px) and (max-width:767px) {
            max-height: 329px;
        }

        @media screen and (min-width:768px) and (max-width:1199px) {
            max-height: 240px;
            max-width: 240px;
        }

        @media screen and (min-width:1200px) {
            max-height: 239px;
            max-width: 240px;
        }
    }
}


// For Mobile devices
@media screen and (max-width: 767px) {

    // For grid alignments for box3
    .box3 {
        height: auto;
        margin-top: auto;
    }

    // For viewbox1 and viewbox2 postions
    .view .viewbox1,
    .view .viewbox2 {
        height: 320px;
    }

    .phn-image-sctn,
    .email-image-sctn {
        height: 100%;

    }
}

// For Large Devices
@media screen and (min-width: 768px) and (max-width: 1100px) {

    .view .viewbox1,
    .view .viewbox2 {
        height: 100%;
    }

}

@media screen and (min-width: 1101px) and (max-width: 1324px) {

    .view .viewbox1,
    .view .viewbox2 {
        height: 100%;
    }

    .phn-image-sctn,
    .email-image-sctn {
        height: 100%;
    }
}

div.sticky {
    position: fixed;
    width: 1%;
    z-index: 99999;
    float: right;
    right: 0;
    bottom: 15px;
    margin-right: 65px;
    margin-bottom: 2px;
}

.iframe-container {
    position: relative;
    // opacity: 70%;
    // background-color: #16213E;
    /* Adjust the color and opacity as needed */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-sctn {
    // fill: rgba(22, 33, 62, 0.68);
    // filter: drop-shadow(0px 2px 25px rgba(0, 33, 91, 0.20));

}

.address-container-background {
    background-image: url("../../../assets/images/mapBackground.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    // padding: 22% 0px;
    position: absolute;
    width: 50%;
    height: 70vh;
    z-index: 99;
    left: 13%;
}

.address-container {
    background: #ffffff;
    box-shadow: 0px 2px 25px rgb(0, 33, 91/20%);
    max-width: 30%;
    padding: 0;

    @media screen and (min-width: 501px) and (max-width: 767px) {
        max-width: 40%;
    }

    // @media screen and (min-width: 992px) and (max-width: 1080px) {
    //     left: -2%;
    // }
    // @media screen and (min-width: 768px) and (max-width: 991px) {
    //     left: -5%;
    // }
    @media screen and (min-width: 520px) and (max-width: 767px) {
        max-width: 40%;
    }

    @media screen and (min-width: 350px) and(max-width:500px) {
        max-width: 60%;
    }

    @media screen and (min-width: 250px) and (max-width: 349px) {
        max-width: 85%;
    }

    @media screen and (min-width: 150px) and (max-width: 249px) {
        max-width: 90%;
    }

    @media screen and (max-width: 149px) {
        max-width: 200%;
        width: max-content;
    }
}

.address-image-sctn {
    background: #ffa20d;
    width: 100%;
    height: 2%;
}

.address-text-container {
    // padding: 15px 0px 25px 15px;

    p:first-child {
        color: #41444B;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .contactus-but {
        background-color: #033B64;
        border: none;
        color: white;
        margin-top: 8px;
        height: 40px;

        @media screen and (max-width:241px) {
            height: 50px;
            font-size: 12px;
            width: 115px;
        }

        @media screen and (min-width:992px) and (max-width:1199px) {
            height: 55px;

            width: 200px;
        }

        @media screen and (min-width:1200px) {
            height: 50px;

            width: 220px;
        }
    }

    p {
        color: #033B64;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        margin-bottom: 0px;

        @media screen and(max-width:430px) {
            font-size: 18px;
        }
    }
}

.phone-sctn {
    @media screen and(max-width:769px) {
        top: 45px;
        left: -15px;
    }

    // @media screen and(min-width:768px) and (max-width: 1024px) {
    //     top: 100px;
    //     left: 0%;
    // }

    // @media screen and(min-width:1025px) {
    //     top: 100px;
    //     left: 3%;
    // }
}

.phn-image-sctn {
    background: #ffa20d;

    @media (min-width: 768px) {
        height: 32%;
    }

    padding: 2px;

    img {
        padding: 2px;
    }
}

.phn-text-sctn {
    margin: 0px 0px 0px 11px;

    p:first-child {
        color: #41444B;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 7px;
    }

    p {
        color: #16213E;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;

        @media screen and (max-width: 200px) {
            font-size: 10px;

        }

        @media screen and (min-width: 768px) {
            width: max-content;
        }
    }
}

.email-sctn {
    // top: 102px;
    // left: -5%;

    @media (max-width: 767px) {
        top: 91px;
        left: -14px;
    }
}

.email-image-sctn {
    background: #033b64;
    padding: 2px;

    @media (min-width: 768px) {
        height: 32%;
    }

    img {
        padding: 2px;
    }
}

// .carousel-control-next-icon,
// .carousel-control-prev-icon {
//     background-color: #ffa20d;
// }
// .carousel-indicators li,
// .carousel-indicators li.active {
//     background-color: #ffa20d;
// }
// .carousel-indicators li.active {
//     //background-color: #033b64;
//     border-bottom: transparent !important;
// }
.email-text-sctn {
    margin: 0px 0px 0px 11px;

    p:first-child {
        color: #41444B;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 7px;
    }

    p {
        color: #16213E;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @media screen and (max-width: 200px) {
            font-size: 10px;

        }
    }
}

.dots {
    position: absolute;
    left: 58px;
    bottom: 17%;

    img {
        padding: 0px 1px 0px 1px;
    }
}

.learnmore {
    width: 14px !important;
    height: 15px !important;
}

.arrowbutton {
    height: 15px;
    width: 22px;
    margin-top: -9px;

    @media screen and (max-width:260px) {
        margin-top: -13px;
    }
}

.completed-projects {
    @media screen and(max-width:767px) {
        margin-top: .5rem;
    }
}

.test-bottom {
    @media screen and (min-width:768px) {
        margin-bottom: 4rem !important;
    }

    @media screen and (max-width:767px) {
        margin-bottom: 5rem !important;
    }

}

.client-content {
    height: 310px;

    @media screen and (min-width:768px) {
        height: 238px
    }

    @media screen and (min-width:301px) and (max-width:320px) {
        height: 263px
    }

    @media screen and (min-width:321px) and (max-width:349px) {
        height: 250px
    }

    @media screen and (min-width:350px) and (max-width:394px) {
        height: 216px
    }

    @media screen and (min-width:395px) and (max-width:500px) {
        height: 231px
    }

    @media screen and (min-width:501px) and (max-width:557px) {
        height: 215px
    }

    @media screen and (min-width:558px) and (max-width:767px) {
        height: 205px
    }

    @media screen and (max-width:250px) {
        height: 330px
    }
}

.new-banner {
    background-image: url('../../../assets/images/homeBannerNew1.svg');
}

.first-banner {
    background-image: url('../../../assets/images/homeBanner1.svg');
}

.second-banner {
    background-image: url('../../../assets/images/homeBanne2.png');

    button {
        position: absolute;
        bottom: 32%;
        left: 7%;
        background: #000000;
        border-radius: 67px;
        z-index: 99;
    }
}

.third-banner {
    background-image: url('../../../assets/images/homeBanner3.svg');
}

.four-banner {
    background-image: url('../../../assets/images/homeBanner4.svg');
}

.five-banner {
    background-image: url('../../../assets/images/homeBanne5.png');

    button {
        position: absolute;
        bottom: 25%;
        left: 7%;
        background: transparent;
        border-radius: 67px;
        border: 1px solid #FFAE2B;
        z-index: 99;
    }
}

.six-banner {
    background-image: url('../../../assets/images/homeBanner6.svg');
}

.first-banner-container {
    // background: rgba(35, 35, 35, 0.74);
    // backdrop-filter: blur(2px);
    position: absolute;
    // left: 15%;
    // bottom: 21%;
    // height: 50%;
    left: 5%;
    top: 21%;
    height: auto;

    // width: 41%;
    p {
        font-weight: 700;
        font-size: 56px;
        line-height: normal;
        color: #FFFFFF;
    }

    .left-bar {
        width: 0px;
        height: auto;
        border-left: 10.8611px solid #FFA20D;
    }

    button {
        text-align: right;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 15px;
        line-height: 30px;
        background: #FFA20D;
        border-radius: 11px;
        border: none;
    }

    button:nth-child(2) {
        background: transparent;
        backdrop-filter: blur(2px);
        text-align: right;
        color: #FFA20D;
        border: 1px solid #FFA20D;
    }
}


.find-project-container {
    position: relative;
    z-index: 99;
    margin: auto;
}

.find-project-container-box {
    height: auto;
    //margin-top: -51px; 
    margin-top: -22%;
    background: #FFFFFF;
    box-shadow: -4px -20px 24px rgba(0, 0, 0, 0.15), 4px 10px 24px rgba(143, 143, 143, 0.25);
    border-radius: 32px;

    .subBox1 {
        height: auto;
        display: flex;
        flex-direction: column;
        font-size: 23px;
        color: #033b64;

        @media screen and (min-width:768px) and (max-width:1023px) {
            font-size: 21px;

        }
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: rgba(35, 35, 35, 0.74);
    }

    select {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid #DBDBDB;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        text-transform: capitalize;
        color: #000000;
        // text-align: left;
    }

    select {
        text-align-last: left;
    }

    a {
        button {
            float: right;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-align: right;
            color: #FFFFFF;
            background: #033B64;
            border-radius: 11px;
            text-decoration: none;
        }
    }

}

.home-video {
    flex-direction: column;
    align-items: center;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 65px;
        text-align: center;
        text-transform: capitalize;
        color: #033B64;
    }

}

.home-video-background {
    //background-image: url('../../../assets/images/homeVideo1.png');
    background-repeat: no-repeat;
    // background-position: center center;
    background-size: cover;
    //padding: 11%;
    //width: 90%;
    position: relative;
    text-align: center;


    img {
        width: 80%;
    }
}

.video {
    width: 40% !important;
    position: absolute;
    margin: auto;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 63% !important;
}

.thirdFold {
    background-image: url('../../../assets/images/thridFold.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    // padding: 22% 0px;
    position: relative;
    text-align: center;
}

.thirdFold-container {
    p:nth-child(1) {
        font-weight: 700;
        font-size: 38px;
        line-height: 58px;
        color: #033B64;

    }

    p {
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #41444B;
        //text-align: left;
    }

    button {
        float: left;
        background: #033B64;
        border-radius: 15px;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
    }
}

.thirdFold-smallBox-container {
    //flex-wrap: wrap;
    justify-content: space-between;
    gap: 16;
}

.thirdFold-smallBox::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 2;
}

.thirdFold-smallBox {
    background-image: url('../../../assets/images/third-background-image.png');
    border-radius: 16px;
    width: 23%;
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), url(<path-to-image>), lightgray 0% 0% / 100px 100px repeat;

    p:nth-child(2) {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        color: #033B64;

    }



    img {
        width: 15%;
    }

    ul {
        list-style: unset;
    }
}

.background-opacity {
    background-color: #eeee;
    // height: 100vh; 
}

.fourthFold {
    // background: #F8F8F8;
    height: auto;
}

.fourthFold-header {
    justify-content: space-between;
    align-items: center;

    h4 {
        color: #033B64;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
    }

    button {
        background: #033B64;
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
    }
}

.keyword-box {

    border: 1px solid #FFA20D;
    border-radius: 10px;
    background: #F8F8F8;
    text-align: center;
    color: #FFA20D;

    p {
        margin-bottom: unset;
        font-size: 15px;
    }

    a {
        color: #FFA20D;
    }
}

.keyword-container {
    background: #F8F8F8;
}

.testimonial-container {
    background-image: url('../../../assets/images/Hometestimonial1.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    // padding: 22% 0px;
    position: relative;
    height: 80vh;

    h4 {
        color: #202020;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        /* 106.25% */
    }
}

.testimonial-container-box {
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    //flex-direction: column;
}

// .active {
//     border-bottom: transparent !important;
// }

@media screen and (min-width:997px) and (max-width:1300px) {
    .first-banner-container {
        p {
            font-size: 39px;
            line-height: 50px;
        }
    }

    .menu {
        a {
            margin-left: 10px !important;
            margin-right: 0px !important;
        }
    }
}

@media screen and (min-width:864px) and (max-width:1195px) {
    .homepage {
        padding: 35% 0px;
    }
}

@media screen and (min-width:936px) and (max-width:1195px) {
    .first-banner-container {
        p {
            font-size: 30px;
            line-height: 50px;
        }
    }
}

@media screen and (min-width:830px) and (max-width:935px) {
    .first-banner-container {

        // width: 46%;
        p {
            font-size: 25px;
            line-height: 50px;
        }
    }

    .home-video {
        h4 {
            font-size: 25px;
            line-height: 50px;
        }
    }
}

@media screen and (min-width:700px) and (max-width:829px) {
    .homepage {
        padding: 38% 0px;
    }

    .first-banner-container {

        // width: 46%;
        p {
            font-size: 25px;
            line-height: 50px;
        }
    }
}

@media screen and (min-width: 670px) and (max-width:811px) {
    .homepage {
        padding: 39% 0px;
    }

    .first-banner-container {
        width: 70% !important;

        p {
            font-size: 25px;
            line-height: 50px;
        }
    }
}

@media screen and (min-width: 590px) and (max-width:669px) {
    .homepage {
        padding: 40% 0px;
    }

    .first-banner-container {
        width: 70% !important;

        p {
            font-size: 15px;
            line-height: 35px;
        }
    }
}

@media screen and (min-width: 455px) and (max-width:589px) {
    .homepage {
        padding: 43% 0px;
    }

    .first-banner-container {
        width: 76% !important;

        p {
            font-size: 15px;
            line-height: 35px;
        }

        button {
            font-size: 10px;
            line-height: 20px;
        }
    }
}

@media screen and (min-width: 328px) and (max-width:455px) {
    .homepage {
        padding: 50% 0px;
    }

    .first-banner-container {
        bottom: 35%;
        width: 76% !important;

        p {
            font-size: 12px;
            line-height: 21px;
        }

        button {
            font-size: 9px;
            line-height: 10px;
        }
    }
}

@media screen and (min-width:830px) and (max-width:1300px) {
    .find-project-container-box {
        margin-top: -15%;
    }
}

@media screen and (min-width:455px) and (max-width:829px) {
    .find-project-container-box {
        margin-top: -20%;
    }
}

@media screen and (min-width:328px) and (max-width:454px) {
    .find-project-container-box {
        margin-top: -26%;
    }
}

@media screen and (min-width:894px) and (max-width: 1379px) {
    .thirdFold-smallBox {
        width: 33%;
        margin-top: 10px;
    }
}

@media screen and (min-width:415px) and (max-width:894px) {
    .thirdFold-smallBox {
        width: 45%;
        margin-top: 10px;

        p {
            font-size: 11px;
            line-height: 20px;
        }

        img {
            width: 10%;
        }
    }

    .thirdFold-container p:nth-child(1) {
        font-size: 30px;
        line-height: 46px;
    }

    // .thirdFold-smallBox img {
    //     width: 10%;
    // }
    .home-video {
        h4 {
            font-size: 25px;
            line-height: 50px;
        }
    }
}

@media screen and (min-width:264px) and (max-width:414px) {
    .thirdFold-smallBox {
        width: 100%;
        margin-top: 10px;

        p {
            font-size: 11px;
            line-height: 20px;
        }

        img {
            width: 10%;
        }
    }

    .thirdFold-container p:nth-child(1) {
        font-size: 20px;
        line-height: 25px;
    }

    .home-video {
        h4 {
            font-size: 20px;
            line-height: 30px;
        }
    }

}

@media screen and (min-width:768px) and (max-width:991px) {
    .keyword-box {
        margin-right: 10px !important;

        p {
            font-size: 12px;
        }
    }
}

@media screen and (min-width:768px) and (max-width:996px) {
    .menu {
        a {
            margin-left: 10px !important;
            margin-right: 0px !important;
        }
    }
}

@media screen and (min-width:328px) and (max-width:767px) {
    .keyword-container {
        margin-top: 20%;
    }

    .address-container-box {
        position: unset !important;
        top: unset !important;
        left: unset !important;
    }

    .address-sctn {
        margin-right: 0px !important;
        margin-left: 0px !important;

    }

    .address-text-container {
        button {
            font-size: 12px;
        }
    }

    .testimonial-container {
        img {
            width: 100%;
        }
    }
}

.address-container-box {
    position: relative;
    top: -79px;
    left: 20px;
}


.base {
    cursor: pointer;
    z-index: 9999;

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.28px;
        text-align: center;
        color: #41444B;
        margin-bottom: 0px;
    }
}

.hide-content {
    display: none;
    background-color: #ffff;
    border-radius: 0px 0px 16px 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.10) 100%);
    backdrop-filter: blur(10px);
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 70%;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    button {
        position: relative;
        top: 40%;
    }
}

.base:hover .hide-content {
    display: block;
}

.builder-box {
    border-radius: 16px;
    background: #FFF;
    display: flex;
    // padding: 0% 30%;
    flex-direction: column;
    align-items: center;
    // gap: 16px;
    justify-content: center;

    p:nth-child(2) {
        margin-bottom: 0;
        color: #16213E;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
    }

    p:nth-child(3) {
        color: #033B64;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
    }
}

.fourthFold-image {
    margin-top: 14%;
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: center;
    left: 7%;
}

.subscription_Fold {
    background-image: url('../../../assets/images/subscriptionbbackground.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    // padding: 22% 0px;
    position: relative;
    text-align: center;

    h4 {
        font-size: 44px;
        font-weight: 700;
        line-height: 66px;
        letter-spacing: 0px;


    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;


    }
}

.subscription_box {
    position: relative;
    background-color: #fff;
    width: 371px;
    height: 553px;
    text-align: left;

    h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        // margin-bottom: 0px;
    }

    button {
        position: absolute;
        background-color: #033B64;
        opacity: 10%;
        bottom: 5%;
        text-align: center;
        padding: 10px 10px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0px;
        border-radius: 4px;
    }

    img {
        margin-right: 15px;
    }

    span {
        font-size: 10px;
        background-color: #033B64;
        border-radius: 6px;
        padding: 2px 10px 2px 10px;
        color: #fff;
    }
}

.subscription_box_center {
    position: relative;
    background-color: #033B64;
    width: 371px;
    height: 564px;
    text-align: left;
    margin-right: 2%;
    margin-left: 2%;
    box-shadow: 0px 10px 10px 0px #033B6440;

    h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        color: #FFFFFF;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        // margin-bottom: 0px;
        color: #FFFFFF;
    }

    button {
        position: absolute;
        background-color: #fff;
        bottom: 5%;
        text-align: center;
        padding: 10px 10px;
        color: #033B64;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0px;
        border-radius: 4px;
    }

    img {
        margin-right: 15px;
    }
}