.lighter-text{
    color:#41444B;

}
.cross-img{
    margin-right: 1%;
    float: right;
    margin-top: -3%;
}

.new-butcan {
    @media screen and (min-width:340px) and (max-width:420px) {
        margin-top: 8px;
       
    }
}
.buildings-image{
    @media screen and (max-width:380px) {
        height: 310px;
    }
}
.architect-but{

    @media screen and (min-width:241px) and (max-width:349px) {
        margin-left: 1rem !important;
    }
}

.edit-arch{
    position: absolute;
    top: -28px;
    @media screen and (max-width:399px) {
        width: 96px;
        height: 49px;
        font-size: 12px;
     }
     @media screen and (max-width:767px) {
        
        left: 0px;
     }
     @media screen and (min-width:768px) and (max-width:1037px)   {
        left:26px;
     }
    
}



.landsize-input{
    @media screen and (min-width:992px) {
       width:47% 
    }
    @media screen and (max-width:427px) {
        width:47% 
     }
  
    @media screen and (min-width:768px) and (max-width:800px)   {
        width:38% 
     }
     @media screen and (min-width:428px) and (max-width:632px)   {
        width:50% 
     }
     @media screen and (min-width:801px) and (max-width:991px)   {
        width:41% 
     }
}
.landsize-drop{
    margin-left: 0.2rem !important;
    @media screen and (min-width:768px) {
       
        padding-left: 0rem !important;
     }
     @media screen and (max-width:450px) {
        width:35%;
        
     }
     @media screen and (min-width:350px) and (max-width:450px)   {
        font-size: 12px;
       
     }
     @media screen and (max-width:349px) {
        font-size: 9px;
        
     }
     @media screen and (min-width:768px) and (max-width:991px)   {
        width:45% 
     }
     @media screen and (min-width:992px) {
        width:40% 
     }
 }
 .aboutpro-text{
    @media screen and (min-width:768px) and (max-width:810px)   {
       margin-top: 5%;
     }
 }
 .non-scrollable{
     @media screen and (max-width:767px) {
        max-height: calc(90vh - -25px);
     }
    
    
 }
 .but-size{

     
    @media screen and (min-width:411px) and (max-width:450px) {
        width: 65px;
        font-size: 12px;
        height: 55px;
    }
     @media screen and (min-width:366px) and (max-width:410px) {
         width: 53px;
         font-size: 10px;
         height: 50px;
     }
     @media screen and (min-width:270px) and (max-width:365px) {
        width: 47px;
        font-size: 9px;
        height: 40px;
    }
    @media screen and (max-width:269px) {
        width: 45px;
        font-size: 8px;
        height: 40px;
    }
 }
.buttonscontainer{
    @media screen and (min-width:768px) and (max-width:991px){
        left:-14%;
    }
    @media screen and (min-width:992px) and (max-width:1024px){
        left:-13%;
    }
    @media screen and (min-width:375px) and (max-width:400px){
        left:-26%;
    }
    @media screen and (min-width:401px) and (max-width:767px){
        left:-21%;
    }
    @media screen and (max-width:374px){
        left:-19%;
    }
    @media screen and (min-width:1025px){
        left:-17%;
    }
}
.statedd{
    @media screen and (min-width:1148px) {
        margin-left:0.25rem;
    }
}