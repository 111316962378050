.aboutBuilderProfile {
    .container .py-5 {
        padding-bottom: 0;
        padding-top: 0;
    }
    .row .row-container {
        padding-right: 15px;
        padding-left: 15px;
    }
    h3 {
        color: #033b64;
    }
    h5 {
        color: #033b64;
    }

    p {
        color: #41444b;
        overflow-wrap: break-word;
    }

    .compyLogo {
        box-shadow: 0px 0px 5px #ddd;
    }

    .slideBtns {
        position: relative;
        top: -50%;
        width: 100%;
        button {
            padding: 15px;
            background-color: #fab005;
            border: 0px;
        }
    }

    .pdfblock {
        border: 1px dashed #d4d7dd;
        box-sizing: border-box;
        padding: 40px 40px;
        margin: 20px 0px;
    }

    // ViewBuilder(Customer page)
    ul {
        margin: 0px;
        padding: 0px;
        li {
            margin-top: 40px;
            box-shadow: 0px 0px 5px #ddd;
            img {
                width: 100%;
                height: 100%;
                border-left: 5px solid #fab005;
            }

            h4 {
                font-weight: 700;
            }

            a {
                color: #033b64;
                text-decoration: none;
            }
        }
    }
}

// Portfolio(Add projects popup)
@media screen and (min-width: 572px) and (max-width: 770px) {
    .projectInfo {
        .address,
        .city,
        .pincode {
            .px-3 {
                display: block;
            }
        }
    }
}

@media screen and (min-width: 765px) and (max-width: 1200px) {
    .aboutBuilderProfile {
        .row .row-container {
            .left-tab {
                margin-left: 26px;
            }
        }
    }
}
// portfolio button
.button6{
    background-color:#FFA20D;
    position: absolute;
    right: 333px;
    height: 60px;
    bottom: 128px;
    font-size: 18px;
    @media screen and (max-width:768px) {

   bottom: 25px;
}
}
.newcontact {
    border: none;
    background-color: #fab005;
    color: #fff;
}
.bg-img {
    position: relative;
    .col-md {
        .button6.ml-5.newcontact {
            img {
                margin-bottom: 4px;
            }
            position: absolute;
            right: 230px;
            height: 60px;
            bottom: 135px;
            font-size: 18px;
            &.save-btn {
                border:none;
                right: 320px;
                width: 69px;
            }
            &.cancel-btn {
                border:none;
                right: 210px;
            }
        }
    }
}
@media screen and (min-width: 200px) and (max-width: 319px){
    .breadcrumbs{
        font-size: 35px;
    }
    .bg-img {
        .col-md {
            .button6.ml-5.newcontact {
                // position: absolute;
                // left: -13%;
                // bottom: 28px;
                // height: 50px;
                // font-size: 15px;
                // padding: 0px;
                // right: auto !important;
                font-size: small;
                position: absolute;
                left: -9%;
                width: 109px;
                height: 45px !important;
                bottom: 40px !important;
                padding: 0px !important;
                &.save-btn {
                    // width: 55px;
                    // position: absolute;
                    // left: -10%;
                    right: auto;
                    width: 55px;
                    font-size: small;
                    // padding: 5px !important;
                    bottom: 40px;
                }
                &.cancel-btn {
                    // margin-right: -12% ;
                    // right: 200px;
                    // position: absolute;
                    // left: 30px;
                    // width:55px;
                    font-size: small;
                    width: 59px;
                    left: 56px;
                    bottom: 40px;
                    padding: 0px;
                }
            }
        }
    }
}
@media screen and (max-width: 199px){
    .breadcrumbs{
        font-size: 30px;
    }
    .bg-img {
        .col-md {
            .button6.ml-5.newcontact {
                // position: absolute;
                // left: -13%;
                // bottom: 28px;
                // height: 50px;
                // font-size: 15px;
                // padding: 0px;
                // right: auto !important;
                font-size: small;
                position: absolute;
                left: -20%;
                height: 40px !important;
                bottom: 40px !important;
                padding: 0px !important;
                &.save-btn {
                    // width: 55px;
                    // position: absolute;
                    // left: -10%;
                    right: auto;
                    width: 50px;
                    font-size: small;
                    // padding: 5px !important;
                    bottom: 40px;
                }
                &.cancel-btn {
                    // margin-right: -12% ;
                    // right: 200px;
                    // position: absolute;
                    // left: 30px;
                    // width:55px;
                    font-size: small;
                    width: 50px;
                    left: 27px;
                    bottom: 40px;
                    padding: 0px;
                }
            }
        }
    }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .bg-img {
        .col-md {
            .button6.ml-5.newcontact {
                position: absolute;
                left: 0%;
                bottom: 35px;
                height: 53px;
                width: 144px;
                &.cancel-btn {
                    left: 90px;
                    width: 83px;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 950px) {
    .bg-img {
        .col-md {
            .button6.ml-5.newcontact {
                right: 90px;
                // position: absolute;
                // left: 58%;
                width: 170px;
                height:55px;
                bottom: 143px;
                &.cancel-btn {
                    bottom: 143px;
                    width: 83px;
                    left: 73%;
                }
                &.save-btn {
                    bottom: 143px;
                  right:26%;
                }
            }
        }
    }
}
@media screen and (min-width: 951px) and (max-width:1400px) {
    .bg-img {
        .col-md {
            .button6.ml-5.newcontact {
                right: 21%;
                // position: absolute;
                // left: 58%;

                width: 170px;
                height:55px;
                bottom: 110px;
                &.cancel-btn {
                    left: 68%;
                    width: 83px;
                }
                &.save-btn {
                    // bottom: 98px;
                    right: 31%;
                }
            }
        }
    }
}
@media screen and (min-width: 1401px) {
    .bg-img {
        .col-md {
            .button6.ml-5.newcontact {
                right: 27%;
                // position: absolute;
                // left: 58%;
                width: 170px;
                height:55px;
                bottom: 105px;
                &.cancel-btn {
                    
                    bottom: 105px;
                    left: 62%;
                    width: 83px;

                }
                &.save-btn {
                    bottom: 105px;
                    right: 39%;
                }
            }
        }
    }
}