.Builders {
    background: #F4F4F4;
    padding: 50px 0px;

    button {
        gap: 10px;
        ;
        background: #033B64;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        text-align: right;
        color: #FFFFFF;
    }

    h1 {
        font-size: 55px;
    }

    p {
        font-size: 15px;
    }
}

.buildersBlock {
    .content {
        p:nth-child(1) {
            color: #033B64;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 58px;
        }

        p:nth-child(2) {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.32px;
        }
        button{
            border-radius: 15px;
        }
    }

    .locationList {
        margin: 20px 0px;
        padding-left: 0;

        li {
            color: #767676;
            padding: 15px 0px;
            border-bottom: 1px solid #f0f0f0;
        }
    }

    .search-bar-section {
        display: flex;
        flex-direction: row;

        img {
            position: relative;
            right: 34px;
            margin-right: auto;
            cursor: pointer;
            width: 4%;
        }
    }

    .RatingList {
        padding-left: 0;

        li {
            color: #767676;
            padding: 15px 0px;
            border-bottom: 1px solid #f0f0f0;
            font-size: 13px;
            align-items: center;
            display: flex;

            .rating {
                font-size: 16px;
            }
        }
    }

    .builderscard {
        li {
            list-style: none;
            color: #033b64;
            text-align: center;
            padding: 8px !important;

            .buildercard {
                box-shadow: 0 0 10px #ddd;
                width: 100%;
                border-top: 4px solid #ffa20d;
                box-shadow: 1px 1px 1px 0.5 #ffa20d;
                margin-bottom: 10px;

                img {
                    width: 100%;
                }
            }

            h5 {
                font-size: 16px;
                font-weight: 700;
            }

            p {
                text-align: center;
                font-size: 12px;
            }

            .rating {
                color: #033b64;
                font-size: 13px;
                margin: 0px;
            }

            .buildername {
                p{
                overflow:hidden;
                white-space:nowrap;
                    text-overflow: ellipsis;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px;
                    text-align: center;
                    margin-bottom: 0px;
                }
            
            }

            .location {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 15px;
                text-align: center;
            }
        }
    }
}

// search icon
.input-search {
    max-width: 150%;
}

.bg-img {
    position: relative;

}

.input-cntr {
    position: relative;
    margin-left: auto;

    .search-icon {
        background-image: url(../../../../assets/images/search.svg);
        background-repeat: no-repeat;
        background-position: 10px 12px;
    }

    input {
        background: #FFFFFF;
        border: 1px solid #D4D7DD;
        text-indent: 17px;
    }

    ::placeholder {
        padding-left: 10%;
    }

    @media (max-width:319px) {
        right: auto;
    }

    .search-bar {
        @media (max-width:319px) {
            width: 80%;
            margin-left: 2%;
        }
    }

    img {
        cursor: pointer;

        @media screen and (max-width:319px) {
            right: 20%
        }
    }
}

.close-search {
    margin-left: -34px !important;
    width: 5%;
    margin-right: 5px;
}



.send-inquiry {
    background: rgba(255, 255, 255, 0.17);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.send-inquiry-container {
    h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #41444B;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(65, 68, 75, 0.5);
    }

    button {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        text-align: right;
        color: #FFFFFF;
        background: #033B64;
        border: none;
    }

}

.select-all {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
}

.check-box {
    position: absolute;
    top: 12%;
    left: 15%;
    border: 2px solid #033B64;
    height: 20px;
    width: 20px;
}


// for search icon
@media screen and (min-width: 567px) and (max-width: 768px) {
    .bg-img {
        .input-cntr {
            right: unset;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
    .bg-img {
        .input-cntr {
            right: unset;
        }
    }
}



// For Mobile devices
@media screen and (max-width: 768px) {
    .buildersBlock {
        .RatingList {
            padding-left: 0;
        }

        .builderscard {
            padding-left: 0;
        }

        .w-100 {
            width: 80% !important;
        }
    }
}

// For Large devices
@media screen and (min-width: 768px) {
    .buildersBlock {
        .btn.btn-info {
            display: none;
        }

        .locationList {
            display: block;
        }

    }
}

.project-type {
    font-family: 'Poppins';

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #41444B;
    }

    button {
        background: #033B64;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #FFFFFF;
        padding: 10px 30px;
        border: none;
        margin-left: 15px;
    }

    button:nth-child(4) {
        color: #033B64;
        background: #FFFFFF !important;
    }
}

.select-type-modal {
    display: flex;
    justify-content: space-between;

    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
        color: #033B64;
    }

    img {
        cursor: pointer;
    }

    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 38px;
        text-decoration-line: underline;
        color: #2F80ED !important;
        cursor: pointer;
    }
}

.project-type-box {
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.project-type-box-active {
    background: #FFFFFF;
    border: 1px solid #033B64;
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.project-type-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 10%;
    }

    p {
        margin-top: 10px;
    }
}

.project-type-active {
    border-bottom: 5px solid #033B64 !important;
}

.property-header {
    border-bottom: 2px solid #D4D7DD;
    width: 100%;
    justify-content: space-evenly;

    p {
        margin-bottom: 0px;
        cursor: pointer;
    }

    img {
        width: 10%;
        margin-right: 15px;
    }
}

.project-type-disabled {
    cursor: auto !important;
    opacity: 0.5;
}

.hire-content-list {
    p:nth-child(1) {
        font-weight: 600;
        font-size: 24px;
    }

    p {
        margin-top: 17px;
        font-size: 20px;
        line-height: 18px;
        color: #023A63;
    }

    input {
        margin-right: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .project-type-box {
        width: 62%;
        height: 34px;
    }
}