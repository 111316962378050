.sendInquiry-modal{
    border-top: 4px solid #FFA20D;
    font-family: 'Poppins';
    span{
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #41444B;
    }
    button{
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        text-align: right;
        color: #FFFFFF;
        background: #033B64;
        border: none;
    }
}
.sendInquiry-header{
    justify-content: space-between;
    align-items: center;
    h4{
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 38px;
        color: #00215B;
    }
    img{
       cursor: pointer;
       margin-bottom: 12px;
    }
}

.sendInquiry-container{
    font-family: 'Poppins';
    p{
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #41444B;
    }
    ::placeholder{
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #41444B;
    }
    input{
        background: #FFFFFF;
        border: 1px solid #D4D7DD;
    }
    select{
        background: #FFFFFF;
        border: 1px solid #D4D7DD;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #41444B;
    }
    option[value=""][disabled] {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #41444B;
      }
    
}

.toggle-switch {
    margin-top: 0px !important;
}

.project-container{
    h4{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 54px;
        color: #00215B;
    }
    button{
        background: #033B64;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #FFFFFF;
        padding: 10px 30px;
        border: none;
        margin-left: 15px;
    }
    button:nth-child(2) {
        color: #033B64;
        background: #FFFFFF !important;
    }

}
.edit-image{
    position: relative;
    right: 10%;
    width: 3%;
}

.projectDetails-subheading{
    p{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 38px;
        color: #00215B;
    }
    p:nth-child(2){
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #41444B;
        opacity: 0.87;
    }
}
.land-size{
    select{
        border: none;
        position: relative;
        right: 24%;
        font-style: italic;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        color: #41444B;
    }
    span{
        position: relative;
        right: 24%;
        font-style: italic;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        color: #41444B;
    }
}
.projectname-field{
    background: #FFFFFF;
    border: 1px solid #D4D7DD;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    /* width: 100px; */
    padding: 0 10% 0 3%;
}
