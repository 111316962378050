.projectsPage{
    width: 100%;
    background-image: url("../../../../assets/images/Bgimage.jpg");
    background-repeat: no-repeat;
    background-size: cover;

  .overlay{
      background-color: #033B64;
      opacity: 0.7;
      padding: 150px 0px;
      color: #ffffff;
      p{
         text-align: justify;
      }
  }
  h1{
      font-size: 55px;
  }
    .knowmore{
        padding: 15px 25px;
        background-color: #FAB005;
        border: 0px ;
        color: #ffffff;
        font-size: 13px;
        
    }
    p{
        font-size: 15px;
    }
  
}

.aboutProject{
    
    h4{
        color: #033B64;
     }
     h5{
        color: #16213E;
     }
   

   .slideBtns{
        position: relative;
        top: -50%;
        width: 100%;
        button{
            padding: 15px;
            background-color: #FAB005;
            border: 0px ;
        }
   }

   .pdfblock{
    border: 1px dashed #D4D7DD;
    box-sizing: border-box;
    padding: 40px 40px;
    margin: 20px 0px;
   }
   
   
}
.offset-md-2{
    h1{
        font-weight: 600;
        
    }
}

// .testProject{
//     color: red !important;
// }


.projectInfo{
    // h3{
    //     color: #00215B;
    // }
    h5{
        font-weight: 600;
    }
   
        h3{
            color:#033b64 !important;
        }
      
}
.container{
    h4{
        font-weight: 600;
    }
}
.builder{
    h5{
        font-weight: 600;
    }
}

.contactbut{
    margin-left: 1.5rem;
    height:18px;
    width:18px;
}

.aboutpro{
    position:absolute;

}






