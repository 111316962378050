.Townplanner{
  background-image: url(../../../../assets/images/TownplannerBanner.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  padding: 22% 0px;
  position: relative;
  .bannerButtons{
    position: absolute;
    right: 34%;
    bottom: 25%;
}
button{
  gap: 10px;
  background: #033B64;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  text-align: right;
  color: #FFFFFF;
  border-radius: 6px;
}
}
.disabledCursor{
    cursor:default;
  }

.offset-md-2{
    h1{
      font-weight:700;
    }
  } 