.header {
    right: 0;
    z-index: 100;
    top: 0;
    background: white;
    left: 0;
    position: fixed;
    // height: 110px;

    .headerBox{
        @media screen and (min-width:351px) {
            margin-top: -7px;
        }
    }
    .header-top{
        margin-top: 0.3rem;
    }
  .offset-md-7{
    // @media screen and (min-width:678px) and (max-width:767px){
    //     left:32%;
    // }
    @media screen and (min-width:576px) and (max-width:767px){
        left:22%;
    }
    // @media screen and (min-width:451px) and (max-width:575px){
    //     left:7%;
    // }
    @media screen and (min-width:356px) and (max-width:575px){
        left:18%;
    }
    @media screen and (min-width:331px) and (max-width:355px){
        left:19%;
    }
      @media screen and (min-width:293px)and (max-width:330px) {
          position:absolute;
          left:68%;
      }
      @media screen and (min-width:150px)and (max-width:292px) {
        position:absolute;
        left:70%;
    }
    @media screen and  (max-width:149px) {
        // position:absolute;
        left:10%;
    }
}
    .headerTop {
        display: flex;
        padding-left: 0;
        font-weight: 300;
        @media screen and (max-width:400px) {
            font-size: 12px;        }
        a {
            text-decoration: none;
            font-weight: 300;
           
            color: #033b64 !important;
            // margin: 0px 10px;
        }
    }

    .menu {
        padding: 0px;
        margin: 0px;
        color: #033b64;
        background-color: #ffffff;
        @media (max-width: 767px) {
            border-top:1px solid #aca5a5 ;
            margin:'8px 0';
            height: 90vh;
            width: 40vw;
            position: absolute;
            right: 0%;
        }

        li {
            color: #023A63;
            display: inline-block;
            @media screen and (max-width:299px) {
                font-size: 10px;
            }
            @media screen and (min-width:300px) and (max-width:339px) {
                font-size: 13px;
            }
            @media screen and (min-width:340px) and (max-width:380px) {
                font-size: 14px;
            }
            // @media screen and (min-width:768px) and (max-width:1023px){
            //     transform: scale(0.7);
            // }
        }
        a {
            // margin: 0px 8px;
            // margin: 0px 42px;
            text-decoration: none;
            font-weight: 400;
            color: #033b64 !important;
        }
    }
}

.list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
}

.menu-icon {
    button{
        img{
            height:50px;
            width: 50px;
            @media screen and (max-width:350px) {
                height:35px;
                width: 35px;
             }
     }
 }
     @media (max-width:199px){
        margin-right: -5%;
       
    }
    @media (min-width:200px)and(max-width: 242px) {
        display: initial;
        margin-right: 9%;
    }
    @media (min-width:768px){
        display:none;
    }
    // display: none;
    @media (min-width:243px)and(max-width: 299px) {
        display: initial;
        margin-right: 6%;
    }
    @media (min-width:300px)and (max-width: 520px) {
        display: initial;
        margin-right: -27%;
    }
    @media (min-width:521px)and(max-width: 575px) {
        display: initial;
        margin-right: -21%;
    }
    @media (min-width:576px)and(max-width: 767px) {
        display: initial;
        margin-right: -54%;
    }
    // @media (min-width:678px)and(max-width: 767px) {
    //     display: initial;
    //     margin-right: -77%;
    // }
//     @media (min-width: 657px) and(max-width:759px){
   
//     display: initial;
//     margin-right: -13%;

// }
}
.active {
    color: #033b64 !important;
    border-bottom: 3px solid #033b64 !important;
}
.active-blue{
    color: #ffffff !important;
    border-bottom: 3px solid #ffffff !important;
}
.togglebtn {
    border: 0px;
    // margin-right: 70px;
    background-color: transparent;
}

.mobilemenu {
    position: absolute;
    z-index: 99;
    width: 100%;
    margin-right: 47px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
    // .mobilemenu{
    //     margin-right: 47px;
    // }
    .header {
        .menu {
            li {
                display: block;
            }
        }
    }
}
@media screen and (min-width: 200px) and (max-width: 299px) {
    .d-block {
        display: block !important;
        // left: 236px;
        margin-right: -18%;
        // position: absolute;
    }
}
@media screen and (min-width: 300px) and (max-width: 450px) {
    .d-block {
        display: block !important;
        // left: 236px;
        margin-right: 15%;
    }
}
@media screen and (min-width: 451px) and (max-width: 575px) {
    .d-block {
        display: block !important;
        margin-right: 20%;
    }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
    .d-block {
        display: block !important;
        margin-right: 49%;
    }
}

.col-6{
    @media screen and (max-width:270px) {
        font-size:11px;
        right: 5%;
    }
}
.d-block{
    button{
        img{
            @media (max-width:199px) {
                margin-right: -44px;
                height:25px;
                width: 25px;
            }
        }
    }
}
.headerBox{
    img{
        @media screen and (max-width:300px) {
            height: 75%;
        }
    }
}

.loginSignupPill{
    border: 1px solid #033B64;
    border-radius: 10px;
    padding-left: 8px;

    span{
        background: #033B64;
        border-radius: 10px;
        padding: 5px 6px 5px 8px;
        color: white;
    }
}

.dropdown-wrapper{
    cursor: pointer;
}
.dropdown-container {
    position: absolute;
    flex-direction: column;
   // width: 20%;
    background-color: #ffffff;
    display: none ;
    right: 0;
    margin-top: 0;
    z-index: 1000;
}

.dropdown-container-box{
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    margin-top: 4%;
       
}

.dropdown-wrapper:hover .dropdown-container {
    display:block;
  }

 .dropdown-Subcontainer{
    justify-content: space-evenly;
    p{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 14px !important;
    }
 }
 
 //////////
 
 .login-dropdown-wrapper{
    cursor: pointer;
}

.login-dropdown-container {
        position: absolute;
        flex-direction: column;
        width: 25%;
        background-color: #ffffff;
        display: none ;
        right: 22%;
        margin-top: 0;
        z-index: 1000;
        .text-color{
           li{
            a{
                color: #033b64 !important;
               }

           } 
        }
      
  
}

.after-login{
    right: 15% !important;
}

.login-dropdown-container-box{
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    margin-top: 15%;
}
.login-dropdown-wrapper:hover .login-dropdown-container {
    display:block;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .login-dropdown-container-box {
        margin-top: 20%;
    }
    .login-dropdown-container {
        width: 25%; 
        right: 26%;
    }
    .after-login{
        right: 26% !important;
    }
   
}  

@media screen and (min-width: 991px) and (max-width: 1115px) {
    .login-dropdown-container {
        right: 25%;
    }
    .dropdown-profile-container {
        right: 26%;
    }
}

.dropdown-profile-wrapper{
    cursor: pointer;
}
.dropdown-profile-container {
    position: absolute;
    flex-direction: column;
    width: 20%;
    background-color: transparent;
    display: none ;
    right: 0;
    margin-top: 0;
    z-index: 1000;
    .text-color{
        li{
         a{
             color: #033b64 !important;
            }

        } 
     }
}

.dropdown-profile-container-box{
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    margin-top: 15%;
    background-color: #ffffff;
}

.dropdown-profile-wrapper:hover .dropdown-profile-container {
    display:block;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .dropdown-profile-container-box {
        margin-top: 20%;
    }
    .dropdown-profile-container {
        width: 25%; 
       
    }
  }

  .header1 {
    right: 0;
    z-index: 100;
    top: 0;
    background: #033B64;
    left: 0;
    position: fixed;
    // height: 110px;

    .headerBox{
        @media screen and (min-width:351px) {
            margin-top: -7px;
        }
    }
    .header-top{
        margin-top: 0.3rem;
    }
  .offset-md-7{
    // @media screen and (min-width:678px) and (max-width:767px){
    //     left:32%;
    // }
    @media screen and (min-width:576px) and (max-width:767px){
        left:22%;
    }
    // @media screen and (min-width:451px) and (max-width:575px){
    //     left:7%;
    // }
    @media screen and (min-width:356px) and (max-width:575px){
        left:18%;
    }
    @media screen and (min-width:331px) and (max-width:355px){
        left:19%;
    }
      @media screen and (min-width:293px)and (max-width:330px) {
          position:absolute;
          left:68%;
      }
      @media screen and (min-width:150px)and (max-width:292px) {
        position:absolute;
        left:70%;
    }
    @media screen and  (max-width:149px) {
        // position:absolute;
        left:10%;
    }
}
    .headerTop {
        display: flex;
        padding-left: 0;
        font-weight: 300;
        @media screen and (max-width:400px) {
            font-size: 12px;        }
        a {
            text-decoration: none;
            font-weight: 300;
           
            color: #ffffff !important;
            // margin: 0px 10px;
        }
    }

    .menu {
        padding: 0px;
        margin: 0px;
        color: #ffffff;
        background-color: #033b64;
        @media (max-width: 767px) {
            border-top:1px solid #aca5a5 ;
            margin:'8px 0';
            height: 90vh;
            width: 40vw;
            position: absolute;
            right: 0%;
        }

        li {
            color: #ffffff;
            display: inline-block;
            @media screen and (max-width:299px) {
                font-size: 10px;
            }
            @media screen and (min-width:300px) and (max-width:339px) {
                font-size: 13px;
            }
            @media screen and (min-width:340px) and (max-width:380px) {
                font-size: 14px;
            }
            // @media screen and (min-width:768px) and (max-width:1023px){
            //     transform: scale(0.7);
            // }
        }
        a {
            // margin: 0px 8px;
            // margin: 0px 42px;
            text-decoration: none;
            font-weight: 400;
            color: #ffffff !important;
        }
    }
}
.loginSignupPill2{
    border: 1px solid #ffff;
   // border-radius: 10px;
    padding-left: 8px;

    span{
        background: #ffffff;
      //  border-radius: 10px;
        padding: 5px 6px 5px 8px;
        color: #033B64;
    }
}
.dropdown-container1 {
    position: absolute;
    flex-direction: column;
   // width: 20%;
    background-color: transparent;
    display: none ;
    //right: 0;
    margin-top: 0;
    z-index: 1000;
    .text-color{
        a{
            color: #033b64 !important;
           }
    }
}
.dropdown-wrapper:hover .dropdown-container1 {
    display:block;
  }
  .dropdown-container-box1{
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    margin-top: 4%;
    background-color: #ffffff;
  
}

.dropdown-wrapper-supplier{
    cursor: pointer;
  }
  .dropdown-container-supplier {
   // position: absolute;
    flex-direction: column;
    background-color: #ffffff;
    display: none ;
   // right: 0;
    margin-top: 0;
    z-index: 1000;
  //  width: 35%;
    text-align: center;
}

.dropdown-container-supplier-box{
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    margin-top: 10%;
    padding: 50px
}

.dropdown-wrapper-supplier:hover .dropdown-container-supplier {
    display:block;
  }
